<template>
  <toggle-section
    :is-open="isOpen[getSectionName('bankruptcy', index)]"
    :section="getSectionName('bankruptcy', index)"
    @toggle="toggleTable"
    chevron-position="right"
  >
    <template #titleSection>
      <div class="flex flex-grow items-center space-x-2">
        <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.BANKRUPTCY") }}</lf-h3>
      </div>
    </template>
    <template #content>
      <div class="flex space-x-5 mb-5">
        <property-card class="w-1/2 p-5 rounded border space-x-5">
          <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.DEROG_SUM_CNT_BNKRPTCY") }}</div>
          <lf-h3>
            {{ business.BankruptcySection?.DerogSummaryCntBankruptcy }}
          </lf-h3>
        </property-card>
        <property-card class="w-1/2 p-5 rounded border space-x-5">
          <div>
            {{ $t("DEALS.LEXIS_NEXIS.KYB.TOTAL_DEROG_SUM_CNT_BNKRPTCY") }}
          </div>
          <lf-h3>
            {{ business.BankruptcySection?.TotalDerogSummaryCntBankruptcy }}
          </lf-h3>
        </property-card>
      </div>
    </template>
  </toggle-section>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import ToggleSection from "@/components/ToggleSection.vue";
import PropertyCard from "./PropertyCard.vue";
import type { Business } from "@/models/commercialData/lexisnexis/KybReport";
import { getSectionName } from "@/helpers/lexisnexis";

defineProps({
  business: {
    type: Object as PropType<Business>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isOpen: {
    type: Object,
    required: true
  }
});

const emit = defineEmits<{
  (eventName: "toggle", key: string): void;
}>();
const toggleTable = (section: string) => {
  emit("toggle", section);
};
</script>
